import { defineComponent, computed } from '@vue/composition-api';
import useUserRoles from '@/shared/composables/useUserRoles';
export default defineComponent({
    props: {
        role: {
            type: [
                String,
                Array
            ],
            default: () => ''
        }
    },
    setup(props) {
        const access = useUserRoles();
        const user = computed(() => globalThis.$store.getters['$_app/user']);
        return {
            access: computed(() => {
                if (Array.isArray(props.role)) {
                    if (!props.role.includes('admin') && user.value?.is_admin) {
                        return false;
                    }
                    return !!props.role.find(role => role === 'admin' ? user.value?.is_admin : access.value[role]);
                }
                return props.role === 'admin'
                    ? user.value?.is_admin
                    : access.value[props.role];
            })
        };
    },
    render(_h) {
        return this.$scopedSlots?.default?.({ access: this.access });
    }
});
