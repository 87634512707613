import { defineComponent } from '@vue/composition-api';
import { useAuthorized } from '@/shared/composables/useAuthorized';
import UserProvider from '@/components/UserProvider.vue';
const Access = defineComponent({
    props: {
        role: {
            type: [
                String,
                Array
            ],
            default: () => null
        },
        authorized: {
            type: Boolean,
            default: true
        }
    },
    setup() {
        const isAuthorized = useAuthorized();
        return { isAuthorized };
    },
    render(h) {
        if (this.isAuthorized === this.authorized &&
            !this.role) {
            return this.$slots.default;
        }
        if (this.isAuthorized &&
            this.authorized &&
            this.role) {
            return (<UserProvider role={this.role} scopedSlots={{
                    default: ({ access }) => {
                        return access ? this.$slots.default : undefined;
                    }
                }}/>);
        }
        return null;
    }
});
export default Access;
