import { defineComponent, ref, onMounted, computed } from '@vue/composition-api';
import Accordion from '@/components/Accordion.vue';
import Access from '@/components/Access.vue';
import { AuthPopups, SignUpSources } from '@/Authentication/authentication.const';
import storyblok from '@/mixins/storyblok';
import TrustPublication from '@/components/TrustPublication.vue';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import { useMeta } from '@/shared/composables/useMeta';
import { SITE_URL } from '@/App.vue';
export default defineComponent({
    components: {
        TrustPublication,
        Accordion,
        Access
    },
    mixins: [storyblok],
    setup(_, context) {
        const { root } = context;
        const slug = ref('vendor');
        const questions = [
            'payout',
            'monitoring',
            'offers',
            'booking_request',
            'call',
            'calendars'
        ];
        const user = computed(() => globalThis.$store.getters['$_app/user']);
        onMounted(() => {
            useMeta({
                title: root.$i18n.t('meta.home_vendor.title_tag').toString(),
                description: root.$i18n
                    .t('meta.home_vendor.description_tag')
                    .toString(),
                url: `${SITE_URL}/vendor`,
                noIndex: false
            });
        });
        return {
            questions,
            slug,
            user,
            AuthPopups,
            SignUpSources,
            useGetLocalizedPath
        };
    },
    computed: {
        heroBlock() {
            if (this.story.content && this.story.content.body)
                return this.story.content.body.find(el => el.component === 'Vendor hero');
            return null;
        }
    }
});
